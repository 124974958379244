import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

export default function WebsiteTerms() {
  return (
    <Layout>
      <SEO title="Service Level Standards | Compute Software"/>
      <Helmet></Helmet>
      <section className="terms-hero sub-hero jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="page-title">Service Level Standards</h1>
          <p className="last-updated">Last updated: January 10, 2021</p>
        </div>
      </section>
      <section className="terms-content sub-general-content scrollable-content">
        <div className="container">
          <div className="row">
            <div className="terms col-lg-12">
                <main>
                  <div className="content">
                    <h2>Service Level Standards</h2>
                    <p>If Customer is a party to a mutually executed agreement that specifically references these Service Level Standards as applicable (such as the Compute Software Services Agreement) then the following will apply to that Customer&rsquo;s production environment of the Services.&nbsp;</p>
                    <h3>Services Availability Service Level</h3>
                    <p>Compute Software will provide 99.5% System Availability over one-month periods as measured and monitored by Compute Software or it will make the SLA Credits available as provided below (the &ldquo;<strong>Service Levels</strong>&rdquo;). The Service Level commitment does not apply to any Proof of Concepts or trials of the Services. System Availability will be calculated on a monthly basis as follows: (Actual Availability divided by Total Availability) multiplied by 100 (&ldquo;<strong>System Availability</strong>&rdquo;). The following definitions shall apply:&nbsp;</p>
                    <p>(a) &ldquo;<strong>Actual Availability</strong>&rdquo; means Total Availability minus Downtime, in minutes.&nbsp;</p>
                    <p>(b) &ldquo;<strong>Downtime</strong>&rdquo; means the time (in minutes) that Customer may not access the Services and the Services are not otherwise actively processing a customer-initiated request, in all cases due to failure or malfunction of the Services. Downtime does not include any unavailability of the Services due to the Exclusions listed in Section 2 below.&nbsp;</p>
                    <p>(c) &ldquo;<strong>Force Majeure Event</strong>&rdquo; Any failure or delay caused by or the result of causes beyond the reasonable control of Compute Software and could not have been avoided or corrected through the exercise of reasonable diligence, including, but not limited to, natural catastrophe, terrorist actions, laws, orders, regulations, directions or actions of governmental authorities with jurisdiction, or any civil or military authority, national emergency, insurrection, riot or war, widespread communication outage, or other similar occurrence.&nbsp;</p>
                    <p>(d) &ldquo;<strong>Planned Downtime</strong>&rdquo; means time (in minutes) that the Services are not accessible to Customer (i) for the purpose of reasonably updating, upgrading or maintaining the Services or its underlying infrastructure (for example, without limitation, operating system upgrades, hardware repairs, database backups, data center moves, or the like); (ii) during the then current maintenance windows; and (iii) in each such instance, with Compute Software&rsquo;s reasonable efforts to provide notice (email or in-product) to Customer at least 24 hours in advance.&nbsp;</p>
                    <p>(e) &ldquo;<strong>Total Availability</strong>&rdquo; means 7 days per week, 24 hours per day.&nbsp;</p>
                    <h2>Exclusions</h2>
                    <p>The following are excluded from the definition of Downtime and as such no SLA Credits will be provided for:&nbsp;&nbsp;</p>
                    <ul>
                      <li>Planned Downtime&nbsp;</li>
                      <li>Unavailability attributable to third party service providers&rsquo; equipment, software or network, or by actions of third party service providers&rsquo; personnel or agents, unless that action was undertaken at the express direction of Compute Software, or</li>
                      <li>Unavailability attributable to Customer&rsquo;s equipment, software or network, or by actions of Customer or Customer&rsquo;s personnel or agents, unless that action was undertaken at the express direction of Compute Software, or</li>
                      <li>Unavailability attributable to Force Majeure Event, including general Internet services (e.g. DNS, internet backbone, etc.).&nbsp;</li>
                    </ul>
                    <h2>Reporting</h2>
                    <p>During the term of this Agreement, Compute Software will, upon Customer&rsquo;s request, provide monthly reports to Customer that include Compute Software&rsquo;s performance with respect to the Service Levels and such other metrics as reasonably requested by Customer from time-to-time.&nbsp;</p>
                    <h2>SLA Credits</h2>
                    <p>If Compute Software fails to meet any of the Service Levels, Customer will be eligible to request a credit calculated as follows (the &ldquo;SLA Credits&rdquo;):&nbsp;</p>
                    <h3>SLA Penalty for Service Availability Service Level Failure</h3>
                    <p>If the System Availability during any given month falls below 99.5% and Customer requests an SLA Credit, Compute Software will provide Customer with a SLA Credit equal to the percentage of the total monthly fee applicable to the month in which the Service Level failure occurred corresponding to the System Availability Level set forth in the chart below:&nbsp;</p>
                    <table>
                      <tbody>
                        <tr>
                          <td><strong>System Availability Level</strong></td>
                          <td><strong>SLA Credit</strong></td>
                        </tr>
                        <tr>
                          <td><em>99.0-99.5%</em></td>
                          <td><em>5% of total monthly fee applicable to month in which failure occurred</em></td>
                        </tr>
                        <tr>
                          <td><em>97.5-99.0%</em></td>
                          <td><em>10% of total monthly fee applicable to month in which failure occurred</em></td>
                        </tr>
                        <tr>
                          <td><em>95.0-97.5%</em></td>
                          <td><em>25% of total monthly fee applicable to month in which failure occurred</em></td>
                        </tr>
                        <tr>
                          <td><em>&lt; 95.0%</em></td>
                          <td><em>100% of total monthly fee applicable to month in which failure occurred</em></td>
                        </tr>
                      </tbody>
                    </table>
                    <p>Each SLA Credit will be paid by Compute Software to Customer by way of a credit on the next invoice submitted by Compute Software to Customer, unless no further invoices are issued by Compute Software after the date on which the SLA Credit becomes payable, in which case Compute Software will pay such SLA Credit to Customer within 30 days.</p>
                  </div>
                  <nav className="table-of-contents" >
                    <h3>Table of Contents</h3>
                    <div id="toc" data-toggle="toc"></div>
                  </nav>
                </main>
              </div>
            </div>
        </div>
      </section>
    </Layout>
  )
}
